import { first } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy, Component, HostListener, Inject, inject, TemplateRef
} from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslitPipe } from '@app/pipes/translit.pipe';
import { GlossaryService } from '@services/glossary.service';
import { Tag, TagGroup, TagsService } from '@services/tags.service';

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule,
    TranslitPipe,],
  templateUrl: './basic-sheet.component.html',
  styleUrls: ['./basic-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicSheetComponent<T> {
  public Glossary = GlossaryService.Glossary;
  public currentIndex = 0;

  private tagsService = inject(TagsService);
  private router = inject(Router);

  public template!: TemplateRef<any>;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: {
      item: any;
      template: TemplateRef<any>;
      items: any[];
    },
    private bottomSheetRef: MatBottomSheetRef<BasicSheetComponent<T>>,
  ) {
    this.template = data.template;

    if (data.item && data.items?.length) {
      this.currentIndex = data.items.findIndex(i => i.id === data.item.id);
    }

  }

  @HostListener('document:keydown.escape', ['$event'])
  onClose() {
    this.bottomSheetRef.dismiss(true);
  }

  @HostListener('document:keydown.arrowleft', ['$event'])
  onPreviousIndex() {
    if (this.currentIndex === 0) {
      return;
    }

    this.currentIndex--;
  }

  @HostListener('document:keydown.arrowright', ['$event'])
  onNextIndex() {
    if (this.currentIndex === this.data.items.length - 1) {
      return;
    }

    this.currentIndex++;
  }

  onPlace(place: string) {
    this.tagsService.getItems()
      .pipe(first())
      .subscribe({
        next: (response: Tag[]) => {
          const tag = response.find(i => i.group === TagGroup.cities
            && place.includes(i.name!));
          if (tag) {
            this.bottomSheetRef.dismiss(false);
            this.router.navigateByUrl(`tag/${tag.slug}/znamenitosti`);
          }
        }
      });
  }
}

